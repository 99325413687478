<template>

  <div>

    <!-- Breadcrumb Section Begin -->
    <section class="breadcrumb-section" style="background-color: #F2F4F5 !important;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-text">
              <div class="breadcrumb-option">
                 <img src="/img/ecovillagemall.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb Section Begin -->
    <section class="hero-section">
      <div class="hero-items">
        <b-carousel id="carousel-1" v-model="slide" :interval="8000" fade controls indicators img-width="1920" img-height="750"
          class="single-hero-item set-bg">
          <!-- Slides with custom text -->
          <b-carousel-slide v-for="slider in SliderMall" :key="slider.institucionalId" :img-src="$apiUrlIndex + slider.institucionalImg[0].urlImagem">
            <div class="container">
              <div class="row">
                <div class="col-lg-9 offset-lg-2">
                  <div class="hero-text">
                    <h2><p v-html="slider.resumo1"></p></h2>
                  </div>
                </div>
              </div>
            </div>
          </b-carousel-slide>
    
        </b-carousel>
      </div>
    </section>
    <section class="hero-section mt-5">
      <div class="container">
        <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                  <p v-html="descricaoMallTexto"></p>
                 </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""
                    frameborder="3" height="300" :src="descricaoMallVideo1" width="100%"></iframe>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""
                    frameborder="3" height="300" :src="descricaoMallVideo2"
                    width="100%"></iframe><br />
                </div>
        </div>
      </div>
    </section>

    <section class="mt-5 mb-5" :style="{ backgroundImage:'url(' + BannermallImg1 + ')'}">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-2 hidden-xs">
          </div>
          <div class="col-lg-8 col-md-8 col-sm-10 col-xs-12" style="margin-bottom: 30px; margin-top: 20px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center textcor">
              <span class="titulobannermall">
                <p v-html="BannermallTexto"></p>
              </span>
              <span>
                <img :src="BannermallImg2" class="img-fluid center-block" style="width: 80%; border: 2px solid #ffffff;" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-bg">
        <div id="ctl00_ContentPlaceHolder1_divGaleria" class="container">
           <header class="section-header">
                <h3 class="section-title" style="color:#ffffff;">{{GaleriamallTexto}}</h3>
            </header>
            <div class="row portfolio-container">             
                  <div class="col-lg-12">
                    <div class="images" v-viewer>
                      <img v-for="src in GaleriamallImg" :src="$apiUrlIndex + src.urlImagem"
                        :key="src.institucionalImgId" class="alturaimgGaleria">
                    </div>
                  </div>                    
            </div>
        </div>
    </section>

    <section class="testimonials container_reset mt-5 hidden-sm hidden-xs" :style="{backgroundImage:'url('+ BannerInfoMallImg + ')', height: '350px',backgroundRepeat:'no-repeat' ,backgroundColor: '#f1f6fc',backgroundSize: 'contain' }">
        <div class="container">
            <div class="row row-eq-height">

                <!-- Testimonials Image -->
                <div class="col-xl-6 col-12">
                    <div class="testimonials_image">
                        <div class="background_image"></div>
                        <div class="testimonials_image_overlay" style="height: 350px;"></div>
                    </div>
                </div>

                <!-- Testimonials Content -->
                <div class="col-xl-6 col-12">
                    <div class="testimonials_content">
                        <div class="section_title_container">
                            <div class="section_subtitle">{{BannerInfoMallTitPeq}}</div>
                            <div class="section_title">
                                <h1>{{BannerInfoMallTitGr}}</h1>
                            </div>
                        </div>

                        <!-- Testimonials Slider -->
                        <div class="testimonials_slider_container">
                            <div class="test_slider">
                              <b-carousel id="carousel-1" :interval="5000" controls img-width="400" img-height="150"
                                class="testimonial-slider">
                                <!-- 1 -->
                                <b-carousel-slide v-for="depoimento in informacoesmall" :key="depoimento.institucionalId" img-blank img-alt="">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="test_slide">
                                            <div class="test_text">
                                                <p v-html="depoimento.descricao1"></p>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </b-carousel-slide>
                              
                              </b-carousel>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section class="testimonials container_reset mt-5 hidden-md hidden-lg" style="background-color: #F2F4F5;">
        <div class="container">
            <div class="row row-eq-height">

              <!-- Testimonials Content -->
                <div class="col-xl-6 col-12">
                    <div class="testimonials_content">
                        <div class="section_title_container">
                            <div class="section_subtitle">{{BannerInfoMallTitPeq}}</div>
                            <div class="section_title">
                                <h1>{{BannerInfoMallTitGr}}</h1>
                            </div>
                        </div>

                        <!-- Testimonials Slider -->
                        <div class="testimonials_slider_container">
                            <div class="test_slider">
                              <b-carousel id="carousel-1" :interval="5000" controls img-width="400" img-height="150"
                                class="testimonial-slider">
                                <!-- 1 -->
                                <b-carousel-slide v-for="depoimento in informacoesmall" :key="depoimento.institucionalId" img-blank img-alt="">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="test_slide">
                                            <div class="test_text">
                                                <p v-html="depoimento.descricao1"></p>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </b-carousel-slide>
                              
                              </b-carousel>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="fundocorescura" style="background-color: #0b551e;color: #FFFFFF;padding-top:60px;padding-bottom:60px;"> 
      <div class="container">
        <div class="row">
    
          <!-- Footer Column -->
          <div class="col-xl-6 col-lg-6 footer_col">
            <div class="footer_about">
              <div class="footer_logo">
                <a href="#">
                  <img src="/img/ecovillagemallbranco.png" />
                </a>
              </div>
              <div class="footer_text mt-3">
                <span class="textcor" v-html="RodapeMallTexto">
                </span>
              </div>
            </div>
          </div>
    
          <!-- Footer Column -->
          <div class="col-xl-6 col-lg-6 footer_col">
            <div class="footer_column">
              <div class="footer_title">Contatos</div>
              <div class="footer_info">
                <ul>
                  <!-- Phone -->
                  <li class="d-flex flex-row align-items-center justify-content-start">
                    <div><i class="fa fa-whatsapp lancorclara" style="font-size: 20px;"></i></div>
                    <span>
                      <p class="textcor" style="padding-top: 3px;padding-left: 13px;">{{RodapeMallFone}}</p>
                    </span>
                  </li>
                  <!-- Address -->
                  <li class="d-flex flex-row align-items-center justify-content-start">
                    <div><i class="fa fa-phone lancorclara" style="font-size: 20px;"></i></div>
                    <span>
                      <p class="textcor" style="padding-top: 3px;padding-left: 13px;">{{RodapeMallFone2}}</p>
                    </span>
                  </li>
                  <!-- Email -->
                  <li class="d-flex flex-row align-items-center justify-content-start">
                    <div><i class="fa fa-envelope-o lancorclara" style="font-size: 20px;"></i></div>
                    <span>
                      <p class="textcor" style="padding-top: 3px;padding-left: 13px;">{{RodapeMallEmail}}</p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
//import FiltraImoveis from '../shared/FiltraImoveis.vue'
//import CTA from '../shared/CTA.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    // FiltraImoveis,
    // CTA
  },
  data() {
    return {
      topMall:[],
      TitulotopMall:'',
      FonetopMall:'',
      SliderMall:[],
      slide:'',

      descricaoMall:[],
      descricaoMallTexto:'',
      descricaoMallVideo1:'',
      descricaoMallVideo2:'',

      Bannermall:[],
      BannermallTexto:'',
      BannermallImg1:'',
      BannermallImg2:'',

      Galeriamall:[],
      GaleriamallTexto:'',
      GaleriamallImg:[],

      BannerInfoMall:[],
      BannerInfoMallTitGr:'',
      BannerInfoMallTitPeq:'',
      BannerInfoMallImg:'',

      informacoesmall:[],

      RodapeMall:[],
      RodapeMallFone:'',
      RodapeMallLink:'',
      RodapeMallFone2:'',
      RodapeMallEmail:'',
      RodapeMallTexto:'',

    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/TopoMall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topMall = retornoAPI
        if (this.topMall!= ''){
          this.TitulotopMall = this.topMall[0].nome
          this.FonetopMall = this.topMall[0].referencia
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/slidermall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.SliderMall = retornoAPI.filter(x=> x.visivel)
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/descriçãomall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.descricaoMall = retornoAPI
        if (this.descricaoMall!= ''){
          this.descricaoMallTexto = this.descricaoMall[0].descricao1
          this.descricaoMallVideo1 = this.descricaoMall[0].linkBtn1
          this.descricaoMallVideo2 = this.descricaoMall[0].linkBtn2
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/bannermall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Bannermall = retornoAPI
        if (this.Bannermall != '') {
          this.BannermallTexto = this.Bannermall[0].descricao1
          if (this.Bannermall[0].institucionalImg != '') {
            this.BannermallImg1 = this.$apiUrlIndex + this.Bannermall[0].institucionalImg[0].urlImagem
            this.BannermallImg2 = this.$apiUrlIndex + this.Bannermall[0].institucionalImg[1].urlImagem
          }
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/galeriamall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Galeriamall = retornoAPI
        if (this.Galeriamall != '') {
          this.GaleriamallTexto = this.Galeriamall[0].nome
          if (this.Galeriamall[0].institucionalImg != '') {
            this.GaleriamallImg = this.Galeriamall[0].institucionalImg;
          }
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/BannerInfoMall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.BannerInfoMall = retornoAPI
        if (this.BannerInfoMall != '') {
          this.BannerInfoMallTitGr = this.BannerInfoMall[0].nome
          this.BannerInfoMallTitPeq = this.BannerInfoMall[0].referencia
          if (this.BannerInfoMall[0].institucionalImg != '') {
            this.BannerInfoMallImg = this.$apiUrlIndex + this.BannerInfoMall[0].institucionalImg[0].urlImagem;
          }
        }
      });


      this.$http
      .get(this.$apiUrl + "/institucional/telas/informaçõesmall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.informacoesmall = retornoAPI.filter(x=> x.visivel)

        this.informacoesmall.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/rodapémall")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.RodapeMall = retornoAPI
        if (this.RodapeMall != '') {
          this.RodapeMallFone = this.RodapeMall[0].nome
          this.RodapeMallLink = this.RodapeMall[0].referencia
          this.RodapeMallFone2 = this.RodapeMall[0].campoExtra1
          this.RodapeMallEmail = this.RodapeMall[0].campoExtra2
          this.RodapeMallTexto = this.RodapeMall[0].descricao1
        }
      });
  },
  methods: {

  }
}

</script>

<style>

.alturaimgGaleria{
  height: 150px;
  padding-right: 11px;
  padding-bottom: 10px;
}

.section_subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #bdc4d4;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    line-height: 0.75;
}

.section_title {
    margin-top: 13px;
}

.testimonials_content {
    padding-top: 92px;
    padding-left: 65px;
    padding-bottom: 92px;
}

.testimonials_image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(to right, transparent 50%, #f1f6fc);
    background: transparent;
    background: linear-gradient(to right, transparent 50%, #f1f6fc);
}

.test_text p p {
  font-size: 15px;
  color:#000000;
}

.textcor p{
  color:#ffffff;
  font-size: 14px;
}

.footer_title {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.2;
}

.lancorclara {
    color: #2a9344;
}

.textcor {
    color: #ffffff;
}


.titulobannermall p {
    color: #000000 !important;
    text-shadow: #cccccc 1px 1px!important;
    font-size: 30px !important;
    margin-bottom: 20px !important;
}

.footer_info {
    margin-top: 46px;
}
</style>