<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

    <Header />

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />
    <div v-if="LinkWhats!=''" class="whatsapp-fixo">
      <a :href="LinkWhats[0].referencia" target="_blank">
          <img src="/img/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
      </a>
     </div>
  </div>
</template>

<script>

  import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',


        seoHead:[],
        codigoHead: '',


        seoAnalitics:[],
        codigoAnalitics: '',


        metaHome: [],
        codigoHome: '',

        LinkWhats:[],
        
      }
    },
    created() {

      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        if (this.seobody!= ''){
           this.codigo_SEO = this.seobody[0].codigo
        }
      });


      this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        if (this.seoHead!='' && this.seoHead!= null){
             this.codigoHead = this.seoHead[0].codigo;
             if (this.codigoHead!= null && this.codigoHead!=''){
               document.head.innerHTML += this.codigoHead.trim('"');
             }
        }
        
      });


     this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        if (this.seoAnalitics!='' && this.seoAnalitics != null){
            this.codigoAnalitics = this.seoAnalitics[0].codigo;
             if (this.codigoAnalitics!= null && this.codigoAnalitics!=''){
               document.head.innerHTML += this.codigoAnalitics.trim('"');
             }
        }
        
      });


      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        if (this.metaHome!='' && this.metaHome != null){
          this.codigoHome = this.metaHome[0].codigo;
          if (this.codigoHome!= null && this.codigoHome!=''){
            document.head.innerHTML += this.codigoHome.trim('"');
          }
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/cabeçalho")
      .then((res) => res.json())
      .then((idi) => {
        this.LinkWhats = idi;
      });

    }
  }
</script>

<style>

/* css tmp */
  @import '/css/bootstrap.min.css';
  @import '/css/font-awesome.min.css';
  @import '/css/elegant-icons.css';
  @import '/css/themify-icons.css';
  @import '/css/nice-select.css';
  @import '/css/jquery-ui.min.css';
  @import '/css/owl.carousel.min.css';
  @import '/css/magnific-popup.css';
  @import '/css/slicknav.min.css';
 /* @import '/css/stylepaleta2.css';*/
  @import '/css/stylepaleta3.css';
  @import '/css/depoimentos.css';
  @import '/css/avanceDigital.css';


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

   .ql-size-large{
    font-size: 25px;
  }

  .ql-size-huge{
    font-size: 45px;
  }

  .ql-size-small{
     font-size: 12px;
  }

  .ql-align-justify{
    text-align: justify;
  }
@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
        
    .alturaimg{
      height: 30px;
      padding-right: 1px;
    }

    .single-hero-item .hero-text h2 p {
	font-size: 18px;
	color: #ffffff;
	font-weight: 600;
	margin-bottom: 0px;
	line-height: 1.2;
}

.single-hero-item .hero-text {
	background: rgba(28, 28, 28, 0.6);
	padding: 5px 0 1px;
	text-align: center;
}

.alturaimgGaleria{
  height: 100px !important;
  padding-right: 11px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.testimonials_content {
    padding-top: 92px;
    padding-left: 0px !important;
    padding-bottom: 92px;
}

}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

    .alturaimg{
      height: 40px;
      padding-right: 1px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
    
    .alturaimg{
      height: 60px;
      padding-right: 2px;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }

    .alturaimg{
      height: 60px;
      padding-right: 2px;
    }
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
  
ol, ul {
    padding-left: 0rem !important; 
}

p {
    margin-top: 0;
    margin-bottom: 0.2rem !important;
}

  /* WHATSAPP FIXO */
  .whatsapp-fixo {
      position: fixed;
      bottom: 6px;
      right: 55px;
      z-index: 999;
  }
</style>
