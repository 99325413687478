<template>
  <div>
    <!-- Header Section Begin -->
    <header class="header-section">
      <div class="top-nav">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <b-navbar toggleable="lg" class="main-menu">
                <b-navbar-brand>
                  <router-link to="/" class="">
                    <img src="/img/logopreto.jpg" style="width: 240px;" alt="" />
                  </router-link>
                </b-navbar-brand>
    
                <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars text-white"></i></b-navbar-toggle>
    
                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav class="">
                    <b-nav-item exact to="/">Home</b-nav-item>
                    <b-nav-item exact to="/sobre">Sobre</b-nav-item>
                    <b-nav-item exact to="/imoveis">Imóveis</b-nav-item>
                    <b-nav-item to="/contato">Contato</b-nav-item>
                    <b-nav-item class="hidden-lg hidden-md" to="/ecovillagegarden">Lancamento</b-nav-item>
                  </b-navbar-nav>
                </b-collapse>
              </b-navbar>
            </div>
            <div class="col-lg-4 hidden-xs hidden-sm">
              <div class="top-right">
                <a href="#" @click="$redirect_reload('/ecovillagegarden')" class="property-sub" style="text-decoration: none;">LANÇAMENTO</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-logo">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="nav-logo-right">
                <ul>
                  <li>
                    <i class="icon_phone"></i>
                    <div class="info-text">
                      <span>Contatos:</span>
                      <p>{{headerWhatsapp}}
                      </p>
                    </div>
                  </li>
                  <li>
                    <i class="icon_map"></i>
                    <div class="info-text">
                      <span>Endereço:</span>
                      <p>{{headerEndereco}}</p>
                    </div>
                  </li>
                  <li>
                    <i class="icon_mail"></i>
                    <div class="info-text">
                      <span>Email:</span>
                      <p>{{headerEmail}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
  
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      // Header
      header: [],
      headerWhatsapp: '',
      headerEndereco: '',
      headerEmail: '',
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/cabeçalho")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.header = retornoAPI
      console.log(this.header)
      this.headerWhatsapp = this.header[0].nome
      this.headerEndereco = this.header[0].campoExtra1
      this.headerEmail = this.header[0].campoExtra2
    });


  }

}
</script>

