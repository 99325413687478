<template>

  <div>

     <!-- Breadcrumb Section Begin -->
   <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <h2>MXMV</h2>
                        <div class="breadcrumb-option">
                          <router-link to="/" style="text-decoration: none;"><i class="fa fa-home"></i> Home </router-link>
                          <span>Contato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Breadcrumb Section Begin -->

    <!-- Contact Section Begin -->
    <section class="">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13821.856410183358!2d-51.123795!3d-29.9948275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdde64410628f3ec6!2sMAXIMOV%20Negocios%20Imobiliarios!5e0!3m2!1spt-BR!2sbr!4v1664842761656!5m2!1spt-BR!2sbr" width="100%" height="700" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-7 offset-lg-1">
                            <div class="contact-text">
                                <div class="section-title">
                                    <span>Contato</span>
                                    <h4 v-html="contatoTitulo" style="color:#ffffff;"></h4>
                                </div>
                                <form class="contact-form">
                                    <input v-model="nome" type="text" placeholder="Nome">
                                    <input v-model="email" type="text" placeholder="E-mail">
                                    <input v-model="telefone" type="text" placeholder="Telefone">
                                    <textarea v-model="mensagem" placeholder="Mensagem"></textarea>
                                    <button @click.prevent="enviarDadosFormulario" class="site-btn">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Section End -->

  </div>
</template>

<script>

// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {

  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      contato: [],
      contatoTitulo: '',
      contatoDescricao: '',
      contatoEndereco: '',
      contatoTelefone: '',
      contatoEmail: '',
      contatoEmailRecebe:'',

      nome: '',
			email: '',
      telefone: '',
			mensagem: '',


      // Objeto Contato      
      Contato: {
        NomeEmpresa: "MXMV Negócios Imobiliários",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoTitulo = this.contato[0].resumo1
       this.contatoEmailRecebe = this.contato[0].referencia

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      // this.Contato.Destinatario = this.contatoEmail
      this.Contato.Destinatario = this.contatoEmailRecebe

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Email: " + this.email +
        " <br /> Telefone: " + this.Contato.Assunto +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.email == '' || this.Contato.Assunto == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.email = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

