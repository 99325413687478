<template>

  <!-- Feature Section Begin -->
  <section class="feature-section spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <span>Confira</span>
            <h2 style="color:#000000;">Alguns Destaques</h2>
          </div>
        </div>
      </div>
      <div class="row">
          <carousel class="feature-carousel owl-carousel" :perPageCustom="[[340, 1],[768, 1], [1024,3], [1600,4]]"
            autoplay :autoplayTimeout=8000 autoplayHoverPause :paginationEnabled="true" :paginationSize=10 :loop="true">
            <slide v-for="item in imoveis" :key="item.institucionalId" class="feature-item" style="padding:15px;">
              <a href="#" @click="$redirect_reload('/imovelDetalhe/' + item.nome.replaceAll(' ','-').replaceAll('/','') + '/' + item.institucionalId)" >
              <div  v-if="item.institucionalImg!=''" class="fi-pic set-bg" :style="{ backgroundImage: 'url(' + $apiUrlIndex + item.institucionalImg[0].urlImagem + ')' }">
                <div class="pic-tag">
                  <div class="s-text" style="color:#000000;">{{item.segmentoInstNivel1.nomeSegmento}}</div>
                </div>
              </div>
              <div v-else class="fi-pic set-bg" style="background-Image: url('/img/indisponivel.jpg')">
                <div class="pic-tag">
                  <div class="s-text" style="color:#000000;">{{item.segmentoInstNivel1.nomeSegmento}}</div>
                </div>
              </div>
              </a>
              <div class="fi-text" >
                <div class="inside-text" style="height: 220px;background: #ffffff;">
                  <a href="#" style="text-decoration: none;" @click="$redirect_reload('/imovelDetalhe/' + item.nome.replaceAll(' ','-').replaceAll('/','') + '/' + item.institucionalId)" >
                  <h4 style="color:#000000;">{{item.nome}}</h4>
                </a>
                  <ul>
                    <li><i class="fa fa-map-marker"></i> {{item.campoExtra5}}</li>
                  </ul>
                  <h5 class="price" style="color:#000000;">{{item.textoBtn2}}<span></span></h5>
                </div>
                <ul class="room-features">
                  <li v-if="item.campoExtra1!='' && item.campoExtra1!=null">
                    <i class="fa fa-arrows"></i>
                    <p>{{item.campoExtra1}}</p>
                  </li>
                  <li v-if="item.campoExtra2!='' && item.campoExtra2!=null">
                    <i class="fa fa-bed"></i>
                    <p>{{item.campoExtra2}}</p>
                  </li>
                  <li v-if="item.campoExtra3!='' && item.campoExtra3!=null">
                    <i class="fa fa-bath"></i>
                    <p>{{item.campoExtra3}}</p>
                  </li>
                  <li v-if="item.campoExtra4!='' && item.campoExtra4!=null">
                    <i class="fa fa-car"></i>
                    <p>{{item.campoExtra4}}</p>
                  </li>
                </ul>
              </div>
            </slide>
          </carousel>
      </div>
    </div>
  </section>
  <!-- Feature Section End -->

</template>

<script>
export default {
  data() {
    return {
      imoveis: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/cadimóveis")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.imoveis = retornoAPI.filter((x) => x.visivel && x.destaque)

      });

  }
}
</script>