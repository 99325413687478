<template>
 
  <section v-if="depoimentos.length > 0"  class="testimonial-section set-bg spad" style="background-image: url('/img/testimonial-bg.jpg'); Height: '500px';"
  >
    <div class="container">
      <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h2>Depoimentos</h2>
                    </div>
                </div>
            </div>
      <div class="row">
        <div class="col">
            <b-carousel
              id="carousel-1"
              :interval="5000"
              controls
              indicators
              img-width="1024"
              img-height="350"
              class="testimonial-slider"
            >

              <!-- 1 -->
              <b-carousel-slide v-for="depoimento in depoimentos" :key="depoimento.id"  img-blank img-alt="">
                  <div class="row">
                    <div class="col-12">
                      <div class="ts-item">
                            <p v-html="depoimento.descricao1"></p>
                            <div class="ti-author">
                                <h5>{{ depoimento.nome }}</h5>
                                <span>{{ depoimento.campoExtra1 }}</span>
                            </div>
                        </div>
                    </div>
                  </div>
              </b-carousel-slide>

            </b-carousel>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {

      depoimentosSection: [],
      depoimentosSection_bg: '',
      depoimentos: []

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos%20fundo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentosSection = retornoAPI

      this.depoimentosSection_bg = this.$apiUrlIndex + this.depoimentosSection[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentos = retornoAPI.filter((x) => x.visivel)

      this.depoimentos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })


    });

  }
}
</script>

<style>

.carousel-caption {
    position: absolute;
    right: 15%;
    top: 10% !important;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

</style>