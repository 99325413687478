<template>

  <div>

   <!-- Breadcrumb Section Begin -->
   <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <h2>MXMV</h2>
                        <div class="breadcrumb-option">
                          <router-link to="/" style="text-decoration: none;"><i class="fa fa-home"></i> Home </router-link>
                          <span>Imóveis</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Breadcrumb Section Begin -->

    <!-- Property Section Begin -->
    <section class="property-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h4 class="property-title">Imóveis</h4>
                    <div class="property-list">
                        <div class="single-property-item" v-for="item in imoveis" :key="item.institucionalId">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="property-pic">
                                        <a href="#" @click="$redirect_reload('/imovelDetalhe/' + item.nome.replaceAll(' ','-').replaceAll('/','') + '/' + item.institucionalId)" >
                                           <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="">
                                           <img v-else src="/img/indisponivel.jpg" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="property-text">
                                        <div class="s-text">{{item.segmentoInstNivel1.nomeSegmento}}</div>
                                        <h5 class="r-title">{{item.nome}}</h5>
                                        <div class="room-price">
                                            <span></span>
                                            <h5>{{item.textoBtn2}}</h5>
                                        </div>
                                        <div class="properties-location"><i class="icon_pin"></i> {{item.campoExtra5}}</div>
                                        <p v-html="item.resumo1"></p>
                                        <ul class="room-features">
                                            <li v-if="item.campoExtra1!='' && item.campoExtra1!=null">
                                                <i class="fa fa-arrows"></i>
                                                <p>{{item.campoExtra1}}</p>
                                            </li>
                                            <li v-if="item.campoExtra2!='' && item.campoExtra2!=null">
                                                <i class="fa fa-bed"></i>
                                                <p>{{item.campoExtra2}}</p>
                                            </li>
                                            <li v-if="item.campoExtra3!='' && item.campoExtra3!=null">
                                                <i class="fa fa-bath"></i>
                                                <p>{{item.campoExtra3}}</p>
                                            </li>
                                            <li v-if="item.campoExtra4!='' && item.campoExtra4!=null">
                                                <i class="fa fa-car"></i>
                                                <p>{{item.campoExtra4}}</p>
                                            </li>
                                            <li>
                                                <a href="#" @click="$redirect_reload('/imovelDetalhe/' + item.nome.replaceAll(' ','-').replaceAll('/','') + '/' + item.institucionalId)" class="s-text" style="text-decoration: none;">Ver mais detalhes</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Property Section End -->

  </div>
</template>

<script>
//import FiltraImoveis from '../shared/FiltraImoveis.vue'
//import CTA from '../shared/CTA.vue'




export default {
  components: {
    //FiltraImoveis,
    //CTA
  },
  data() {
    return {    
      SegmentoNivel1Id: this.$route.params.negocio,
      SegmentoNivel2Id: this.$route.params.imovel,
      imoveis: [],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/cadimóveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imoveis = retornoAPI.filter((x) => x.visivel)
      
      if (this.SegmentoNivel1Id> 0 && this.SegmentoNivel2Id== 0)
      {
        this.imoveis = this.imoveis.filter(x=> x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == this.SegmentoNivel1Id)
      }

      if (this.SegmentoNivel2Id>0 && this.SegmentoNivel1Id== 0)
      {
        this.imoveis = this.imoveis.filter(x=> x.segmentoInstNivel2!=null && x.segmentoInstNivel2.segmentoInstNivel2Id == this.SegmentoNivel2Id)
      }

      if (this.SegmentoNivel2Id>0 && this.SegmentoNivel1Id>0)
      {
        this.imoveis = this.imoveis.filter(x=> x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == this.SegmentoNivel1Id)
        this.imoveis = this.imoveis.filter(x=> x.segmentoInstNivel2!=null && x.segmentoInstNivel2.segmentoInstNivel2Id == this.SegmentoNivel2Id)
      }

    });
},
 methods: {
 }
}

</script>

