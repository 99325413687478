<template>
  
    <!-- Footer Section Begin -->
    <footer class="footer-section set-bg" :style="{ backgroundImage: 'url(/img/footer-bg.jpg)' }">
        <div class="container">
            <div class="footer-text">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="footer-logo">
                            <div class="logo">
                                <a href="#"><img src="/img/logopreto.jpg" style="border-radius: 15px;" alt=""></a>
                            </div>
                            <p v-html="headerTitulo"></p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-lg-1">
                        <div class="footer-widget">
                            <h4>Informações</h4>
                            <ul>
                                <li><i class="fa fa-caret-right"></i> <router-link to="/">Home</router-link></li>
                                <li><i class="fa fa-caret-right"></i> <router-link to="/sobre">Sobre</router-link></li>
                                <li><i class="fa fa-caret-right"></i> <router-link to="/imoveis">Imóveis</router-link></li>
                                <li><i class="fa fa-caret-right"></i> <router-link to="/contato">Contato</router-link></li>
                                <li><i class="fa fa-caret-right"></i> <router-link to="/ecovillagegarden">Lançamento</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="footer-widget">
                            <h4>Social</h4>
                            <ul class="social">
                                <li v-if="facebookLink!=''"><i class="ti-facebook"></i> <a v-if="facebookLink!=''" :href="facebookLink" target="_blank">Facebook</a></li>
                                <li v-if="instagramLink!=''"><i class="ti-instagram"></i> <a v-if="instagramLink!=''" :href="instagramLink" target="_blank">Instagram</a></li>
                                <li v-if="youtubeLink!=''"><i class="ti-youtube"></i> <a v-if="youtubeLink!=''" :href="youtubeLink" target="_blank">Youtube</a></li>
                                <li v-if="linkedinLink!=''"><i class="ti-linkedin"></i> <a v-if="linkedinLink!=''" :href="linkedinLink" target="_blank">Linkedin</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-widget">
                            <h4>Contatos</h4>
                            <ul class="contact-option">
                                <li style="display:inline-flex;"><i class="fa fa-map-marker" style="padding-top: 7px;"></i> <span v-html="headerEndereco"></span></li>
                                <li style="display:inline-flex;"><i class="fa fa-phone" style="padding-top: 7px;"></i> <p v-html="headerWhatsapp"></p></li>
                                <li style="display:inline-flex;"><i class="fa fa-envelope" style="padding-top: 7px;"></i> <p v-html="headerEmail"></p></li>
                                <li style="display:inline-flex;"><i class="fa fa-clock-o" style="padding-top: 7px;"></i> <p v-html="headeratendimento"></p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-text">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <a href="https://avancedigital.com.br/" target="_blank">
                            <img class="logo_avance" src="/img/avance.png">
                        </a>   
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
export default {
  data() {
    return {

      footer: [],
      footer_bg: '',
      headerTitulo: '',
      headerWhatsapp: '',
      headerEndereco: '',
      headerEmail: '',
      headeratendimento: '',


      contato: [],
      contatoEndereco: '',
      contatoTelefone: '',
      contatoEmail: '',
      contatoEmailRecebe:'',

      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialLinkedin: [],
      linkedinLink: '',

      email: '',

      // Objeto Contato      
      Contato: {
        NomeEmpresa: "MXMV Negócios Imobiliários",
        Assunto: "Newsletter",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {


    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoTelefone = this.contato[0].campoExtra2
      this.contatoEmail = this.contato[0].campoExtra1
      this.contatoEndereco = this.contato[0].descricao3
      this.contatoEmailRecebe = this.contato[0].referencia

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/informacoes")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.footer = retornoAPI

      this.headerTitulo = this.footer[0].resumo1
      this.headerWhatsapp = this.footer[0].descricao2
      this.headerEndereco = this.footer[0].descricao1
      this.headerEmail = this.footer[0].descricao3
      this.headeratendimento = this.footer[0].descricao4

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialFacebook = retornoAPI.filter((x) => x.visivel)
      if (this.socialFacebook!=''){
      this.facebookLink = this.socialFacebook[0].nome
      }

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialInstagram = retornoAPI.filter((x) => x.visivel)
      if (this.socialInstagram!=''){
      this.instagramLink = this.socialInstagram[0].nome
      }

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/youtube")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialYoutube = retornoAPI.filter((x) => x.visivel)
      if (this.socialYoutube!=''){
      this.youtubeLink = this.socialYoutube[0].nome
      }

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/linkedin")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialLinkedin = retornoAPI.filter((x) => x.visivel)
      if (this.socialLinkedin!=''){
      this.linkedinLink = this.socialLinkedin[0].nome
    }

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      // this.Contato.Destinatario = this.contatoEmail
      this.Contato.Destinatario = this.contatoEmailRecebe

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = "Email: " + this.email
         
      //validar vazios
      if(this.email == '') {
        this.$mensagem_normal("Preencha o campo com seu email");
      
      }else {

        //SE NAO TIVER ANEXO:
        console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.email = ''
        },error => {
           this.$mensagem_normal(error.bodyText)
          this.email = ''
        });

      }
     
    }
  }
}
</script>