<template>

  <div>

    <!-- Breadcrumb Section Begin -->
    <section class="breadcrumb-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-text">
              <h2>MXMV</h2>
              <div class="breadcrumb-option">
                <router-link to="/" style="text-decoration: none;"><i class="fa fa-home"></i> Home </router-link>
                <span>Imóvel Detalhe</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb Section Begin -->

    <!-- Property Details Section Begin -->
    <section class="property-details-section spad">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mb-5" style="text-align: center;">
            <h2 style="color:#FFFFFF;">{{imovelTitulo}}</h2>
            <h6 class="r-title mt-2" style="color:#FFFFFF;">Código: {{imovelCodigo}}</h6>
            <h6 class="r-title mt-2" style="color:#FFFFFF;">Valor: {{imovelValor}}</h6>
            <h6 class="r-title mt-2" style="color:#FFFFFF;">Modalidade: {{imovelModalidade}}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="pd-details-text">
              <section class="imagesGallery">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col text-center px-0">
                      <b-carousel id="carousel-1" v-model="slide" :interval="5000" controls indicators fade
                        img-width="1024" img-height="480" class="slider-height d-flex align-items-center">
                        <!-- Slides with custom text -->
                        <b-carousel-slide v-for="slider in imovel_bg" :key="slider.institucionalId"
                          :img-src="$apiUrlIndex + slider.urlImagem">
                        </b-carousel-slide>
                      </b-carousel>
                    </div>
                  </div>
                  <div class="row align-content-center mt-3 mb-5">
                    <div class="images" style="display: inline-flex;overflow: hidden;padding-left: 3px;" v-viewer>
                      <img v-for="src in imovelDetalhe[0].institucionalImg" :src="$apiUrlIndex + src.urlImagem"
                        :key="src.institucionalImgId" class="alturaimg">
                    </div>
                  </div>
                </div>
              </section>
              <div class="pd-desc">
                <h4>Descrição</h4>
                <p v-html="imovelResumo"></p>
              </div>
              <div class="pd-details-tab">
                <div class="tab-item mb-3">
                  <ul class="nav" role="tablist">
                    <li>
                      <a class="active" data-toggle="tab" href="#tab-1" role="tab">Características</a>
                    </li>
                  </ul>
                </div>
                <div class="tab-item-content">
                  <div class="tab-content">
                    <div class="tab-pane fade-in active" id="tab-1" role="tabpanel">

                      <div class="property-more-table">
                        <div class="property-list mt-3 mb-5">
                          <div class="single-property-item">
                            <div class="property-text">
                              <ul class="room-features">
                                <li v-if="imovelArea!='' && imovelArea!=null">
                                  <i class="fa fa-arrows"></i>
                                  <p>{{imovelArea}}</p>
                                </li>
                                <li v-if="imovelQuartos!='' && imovelQuartos!=null">
                                  <i class="fa fa-bed"></i>
                                  <p>{{imovelQuartos}}</p>
                                </li>
                                <li v-if="imovelBanhos!='' && imovelBanhos!=null">
                                  <i class="fa fa-bath"></i>
                                  <p>{{imovelBanhos}}</p>
                                </li>
                                <li v-if="imovelVagas!='' && imovelVagas!=null">
                                  <i class="fa fa-car"></i>
                                  <p>{{imovelVagas}}</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p v-html="imovelDescricao"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Video Section Begin -->
              <div v-if="imovelVideo!='' && imovelVideo!=null" class="mt-5 text-center"
                style="position: relative; height: 500px;">
                <iframe width="100%" height="500" :src="imovelVideo" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
              <!-- Video Section End -->
              <div v-if="imovelMapa!='' && imovelMapa!=null" class="property-map mt-5"
                style="position: relative; height: 500px;">
                <h4>Localização aproximada</h4>
                <div class="map-inside">
                  <iframe
                    :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + imovelMapa"
                    width="100%" height="450px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                    tabindex="0">
                  </iframe>
                  <div class="icon">
                    <i class="fa fa-home"></i>
                  </div>
                </div>
              </div>
              <div class="property-contactus" style="position: relative;">
                <h4>Gostou? Vamos conversar</h4>
                <div class="row">
                  <div class="col-lg-5">
                    <div class="agent-desc">
                      <p>Temos uma equipe especializada pronta para lhe atender, deixe seus dados que entramos em
                        contato com você!
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 offset-lg-1">
                    <form class="agent-contact-form">
                      <input v-model="nome" type="text" placeholder="Nome*">
                      <input v-model="email" type="text" placeholder="E-mail">
                      <input v-model="telefone" type="text" placeholder="Telefone">
                      <textarea v-model="mensagem" placeholder="Mensagem"></textarea>
                      <button @click.prevent="enviarDadosFormulario" class="site-btn">Enviar</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Property Details Section End -->
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
//import FiltraImoveis from '../shared/FiltraImoveis.vue'
//import CTA from '../shared/CTA.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    // FiltraImoveis,
    // CTA
  },
  data() {
    return {
      imovelDetalhe: [],
      idImovelDetalhe: this.$route.params.idImovel,
      imovelModalidade: '',
      imovelTipo: '',
      imovelCodigo: '',
      imovelCidade: '',
      imovelBairro: '',
      imovelUF: '',
      imovelTitulo: '',
      imovelDescricao: '',
      imovel_bg: [],
      imovelMapa: '',
      imovelArea: '',
      imovelVagas: '',
      imovelBanhos: '',
      imovelQuartos: '',
      imovelVideo: '',
      imovelValor: '',

      imoveisEmDestaque: [],
      contatoEmailRecebe: '',
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
      // Objeto Contato      
      Contato: {
        NomeEmpresa: "MXMV Negócios Imobiliários",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/cadimóveis")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.imovelDetalhe = retornoAPI.filter((x) => x.visivel && x.institucionalId == this.idImovelDetalhe)
        console.log('this.imovelDetalhe')
        console.log(this.imovelDetalhe)
        this.imovelModalidade = this.imovelDetalhe[0].segmentoInstNivel1.nomeSegmento

        this.imovelTitulo = this.imovelDetalhe[0].nome
        this.imovelCodigo = this.imovelDetalhe[0].referencia
        this.imovelResumo = this.imovelDetalhe[0].resumo1
        this.imovelDescricao = this.imovelDetalhe[0].descricao1
        this.imovelArea = this.imovelDetalhe[0].campoExtra1
        this.imovelQuartos = this.imovelDetalhe[0].campoExtra2
        this.imovelBanhos = this.imovelDetalhe[0].campoExtra3
        this.imovelVagas = this.imovelDetalhe[0].campoExtra4
        this.imovelMapa = this.imovelDetalhe[0].campoExtra5
        this.imovelVideo = this.imovelDetalhe[0].linkBtn1
        this.imovelValor = this.imovelDetalhe[0].textoBtn2
        if (this.imovelDetalhe[0].institucionalImg != '') {
          this.imovel_bg = this.imovelDetalhe[0].institucionalImg;
        }


        // this.imoveisEmDestaque = retornoAPI

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20contato")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.contato = retornoAPI
        this.contatoEmailRecebe = this.contato[0].referencia

      });

  },
  methods: {
    enviarDadosFormulario() {
      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmailRecebe
      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      this.Contato.Assunto = "Interesse Imóvel - Cód.: " + this.imovelCodigo
      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail =
        "Nome: " + this.nome +
        " <br /> E-mail: " + this.email +
        " <br /> Telefone: " + this.telefone +
        " <br /> Imóvel: " + this.imovelCodigo + " - " + this.imovelTitulo +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if (this.nome == '' || this.email == '' || this.telefone == '') {
        this.$mensagem_normal("Preencha todos os campos");

      } else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
          .then(response => {
            if (response.ok)
              this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.email = ''
            this.mensagem = ''
            this.telefone = ''
            this.Contato.Assunto = ""
          }, error => {
            this.$mensagem_normal(error.bodyText)
            this.nome = ''
            this.email = ''
            this.mensagem = ''
            this.telefone = ''
            this.Contato.Assunto = ""
          });
      }
    }
  }
}

</script>

