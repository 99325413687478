<template>

  <div>

   <!-- Breadcrumb Section Begin -->
   <section class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <h2>MXMV</h2>
                        <div class="breadcrumb-option">
                          <router-link to="/" style="text-decoration: none;"><i class="fa fa-home"></i> Home </router-link>
                          <span>Sobre nós</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Breadcrumb Section Begin -->

    <!-- About Section Begin -->
    <section class="about-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title">
                        <span>{{sobreTituloDir}}</span>
                        <h2>{{sobreTituloEsq}}</h2>
                        <p v-html="sobreDescricaoEsq"></p>
                    </div>
                    <div class="video-text set-bg" :style="{ backgroundImage: 'url(' + VideoSobreimg + ')', Height: '500px' }">
                        <a v-if="VideoSobrelink!=''" :href="VideoSobrelink" class="play-btn video-popup"><i
                                class="fa fa-play"></i></a>
                        <h4 v-html="VideoSobretexto"></h4>
                        <h2>{{VideoSobretitulo}}</h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="about-text">
                                <h4>{{sobreTituloOperamos}}</h4>
                                <p v-html="sobreOperamos"></p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-text">
                                <h4>{{sobretitulofoco}}</h4>
                                <p v-html="sobrefoco"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Section End -->

    <!-- Depoimentos -->
    <Depoimentos />

    <!-- Agent Section Begin -->
    <section class="agent-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <span>Atendimento</span>
              <h2 style="color:#000000;">Fale como nossos corretores</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="agent-carousel">
            <div class="col-lg-3" v-for="itemcorretor in corretores" :key="itemcorretor.institucionalId">
              <div class="single-agent">
                <div class="sa-pic">
                  <img v-if="itemcorretor.institucionalImg!=''" :src="$apiUrlIndex + itemcorretor.institucionalImg[0].urlImagem" alt="">
                </div>
                <h5 style="color:#000000;">{{itemcorretor.nome}}</h5>
                <p v-html="itemcorretor.resumo1"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Agent Section End -->

    <!-- Partner Carousel Section Begin -->
    <div class="partner-section mb-5 mt-5">
        <div class="container">
            <div class="row">
              <div class="col-lg-3 text-center" v-for="parceiro in parceiros" :key="parceiro.institucionalId">
                <a :href="parceiro.linkBtn1" class="partner-logo" :title="parceiro.nome" target="_blank">
                    <div class="partner-logo-tablecell">
                        <img :src="$apiUrlIndex + parceiro.institucionalImg[0].urlImagem" alt="">
                    </div>
                </a>
              </div>
            </div>
        </div>
    </div>
    <!-- Partner Carousel Section End -->
    

  </div>
</template>

<script>

import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Depoimentos
  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      sobre: [],
      sobreImg: '',
      sobreTituloEsq: '',
      sobreDescricaoEsq: '',
      sobreTituloDir: '',
      sobreTituloOperamos:'',
      sobreOperamos: '',
      sobretitulofoco:'',
      sobrefoco:'',

      VideoSobre: [],
      VideoSobretitulo: '',
      VideoSobretexto: '',
      VideoSobrelink: '',
      VideoSobreimg: '',

      corretores:[],
      parceiros:[],
    }
  },
  created() {
   
    this.$http
    .get(this.$apiUrl + "/institucional/telas/descriçãosobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.sobreTituloEsq = this.sobre[0].nome
      this.sobreTituloDir = this.sobre[0].campoExtra1
      this.sobreDescricaoEsq = this.sobre[0].resumo1
      
      this.sobreTituloOperamos = this.sobre[0].campoExtra2
      this.sobreOperamos = this.sobre[0].descricao1
      this.sobretitulofoco = this.sobre[0].campoExtra3
      this.sobrefoco = this.sobre[0].descricao2
      this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem

    });




    this.$http
    .get(this.$apiUrl + "/institucional/telas/vídeosobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.VideoSobre = retornoAPI

      this.VideoSobretitulo = this.VideoSobre[0].nome
        this.VideoSobretexto = this.VideoSobre[0].descricao1
        this.VideoSobrelink = this.VideoSobre[0].linkBtn1
        if (this.VideoSobre[0].institucionalImg!=''){
          this.VideoSobreimg = this.$apiUrlIndex + this.VideoSobre[0].institucionalImg[0].urlImagem
        }

    });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/corretores")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.corretores = retornoAPI.filter(x => x.visivel);

        this.corretores.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/parceiros")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.parceiros = retornoAPI.filter(x => x.visivel);

        this.parceiros.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });


  }
  
}

</script>

