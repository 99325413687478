<template>

  <div>
    <section class="hero-section">
      <div class="hero-items">
        <b-carousel id="carousel-1" v-model="slide" :interval="8000" fade controls indicators img-width="1920" img-height="750"
          class="single-hero-item set-bg">
          <!-- Slides with custom text -->
          <b-carousel-slide v-for="slider in Sliders" :key="slider.institucionalId" :img-src="$apiUrlIndex + slider.institucionalImg[0].urlImagem">
            <div class="container">
              <div class="row">
                <div class="col-lg-9 offset-lg-2">
                  <div class="hero-text">
                    <p class="room-location"><i class="icon_pin"></i> {{slider.campoExtra1}}</p>
                    <a :href="slider.linkBtn1" style="text-decoration: none;">
                      <h2>{{slider.nome}}</h2>
                    </a>
                    <div class="room-price hidden-xs hidden-sm">
                      <span>{{slider.campoExtra2}}</span>
                    </div>
                    <ul class="room-features hidden-xs hidden-sm">
                      <li>
                        <center>
                          <img src="/img/lote2.png" style="width: 30px;" />
                          <p>{{slider.campoExtra3}}</p>
                        </center>
                      </li>
                      <li>
                        <center>
                          <img src="/img/segurança1.png" style="width: 30px;" />
                          <p>{{slider.campoExtra4}}</p>
                        </center>
                      </li>
                      <li>
                        <center>
                          <img src="/img/segurança2.png" style="width: 30px;" />
                          <p>{{slider.campoExtra5}}</p>
                        </center>
                      </li>
                      <li>
                        <center>
                          <img src="/img/casa3.png" style="width: 30px;" />
                          <p>{{slider.campoExtra6}}</p>
                        </center>
                      </li>
                    </ul>
                    
                  </div>
                </div>
              </div>
            </div>
          </b-carousel-slide>
    
        </b-carousel>
      </div>
    </section>
    <!-- Search Form Section Begin -->
    <div class="search-form">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="search-form-text">
              <div class="search-text">
                <i class="fa fa-search"></i>
                Encontre seu Imóvel
              </div>
            </div>
            <form class="filter-form">
              <div class="first-row row">
                <div class="col-md-4">
                  <small>Negócio</small>
                  <v-select
                  v-model="TiposNegocioselected"
                  @input="CarregaImovel()"
                  :options="TiposNegocio"
                  :value="TiposNegocio.segmentoInstNivel1Id"
                  single-line
                  label="nomeSegmento"
                  class="style-chooser w-100"
                >
                </v-select>
                </div>
                <div class="col-md-4">
                  <small>Imóvel</small>
                  <v-select
                  v-model="Tiposelected"
                  :options="TiposImovel"
                  :value="TiposImovel.segmentoInstNivel2Id"
                  single-line
                  label="nomeSegmento"
                  class="style-chooser w-100"
                >
                </v-select>
                </div>
                <div class="col-md-4 alturacel mt-4">
                  <button @click="ValidaBusca()" class="search-btn" style="height: 40px;">Pesquisar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Form Section End -->

    <!-- How It Works Section Begin -->
    <section class="howit-works spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <span>{{PodemosajudarSubTitulo}}</span>
              <h2>{{PodemosAjudarTitulo}}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="item in PodemosAjudarItens" :key="item.institucionalId">
            <div class="single-howit-works">
              <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="" style="width: 100px;">
              <h4>{{item.nome}}</h4>
              <p v-html="item.descricao1"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- How It Works Section End -->

    <!-- Feature Section Begin -->
    <Destaques />
    <!-- Feature Section End -->

    <!-- Video Section Begin -->
    <div class="video-section set-bg" :style="{ backgroundImage: 'url(' + videoHomeImg + ')', Height: '500px' }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="video-text">
              <a v-if="videoHomeLink!=''" href="#" @click="$redirect_reload(videoHomeLink)" class="play-btn video-popup"><i
                  class="fa fa-play"></i></a>
              <h4 v-html="videoHomeTexto"></h4>
              <h2>{{videoHomeTitulo}}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Section End -->

    <!-- Top Properties Section Begin -->
    <div class="top-properties-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="properties-title">
              <div class="section-title">
                <span>Laçamentos</span>
                <h2>Últimas Unidades</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <carousel class="top-properties-carousel owl-carousel" :perPageCustom="[[340, 1],[768, 1], [1024,1], [1600,1]]"
          autoplay :autoplayTimeout=8000 autoplayHoverPause :paginationEnabled="true" :paginationSize=10 :loop="true">
          <slide v-for="item in Ultimas" :key="item.institucionalId" class="single-top-properties" style="padding:15px;">
              <div class="row">
                <div class="col-lg-6">
                  <div class="stp-pic">
                    <a href="#" @click="$redirect_reload(item.linkBtn1)">
                      <img :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="">
                    </a>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="stp-text">
                    <div class="s-text">{{item.referencia}}</div>
                    <a href="#" @click="$redirect_reload(item.linkBtn1)" style="text-decoration: none;"><h2>{{item.nome}}</h2></a>
                    <div class="room-price">
                      <span>{{item.campoExtra1}}</span>
                      <h4>{{item.campoExtra2}}</h4>
                    </div>
                    <div class="properties-location"><i class="icon_pin"></i> {{item.campoExtra3}}</div>
                    <p v-html="item.resumo1"></p>
                  </div>
                </div>

            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <!-- Top Properties Section End -->

    <!-- Agent Section Begin -->
    <section class="agent-section spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <span>Atendimento</span>
              <h2 style="color:#000000;">Fale como nossos corretores</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="agent-carousel">
            <div class="col-lg-3" v-for="itemcorretor in corretores" :key="itemcorretor.institucionalId">
              <div class="single-agent">
                <div class="sa-pic">
                  <img v-if="itemcorretor.institucionalImg!=''"
                    :src="$apiUrlIndex + itemcorretor.institucionalImg[0].urlImagem" alt="">
                </div>
                <h5 style="color:#000000;">{{itemcorretor.nome}}</h5>
                <p v-html="itemcorretor.resumo1"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Agent Section End -->
    <b-modal v-model="modalShow" hide-header hide-footer id="modal-1" title="MXMV" style="background-color: #F2F4F5;">
      <p class="my-4">A Maximov Negócios Imobiliários agora virou <strong>MXMV Soluções Imobiliárias</strong>. Atualizamos nossa marca mas continuamos com os mesmos princípios e responsabilidades com nossos clientes.</p>
      <b-button class="mt-3" block @click="$bvModal.hide('modal-1')">Entendi</b-button>
    </b-modal>
    
  </div>
</template>

<script>

//import FiltraImoveis from '../shared/FiltraImoveis.vue'
import Destaques from '../shared/Destaques.vue'

export default {
  components: {
    //FiltraImoveis,
    Destaques
  },
  data() {
    return {
      modalShow: true,
      Sliders:[],
      PodemosAjudar: [],
      PodemosAjudarTitulo: '',
      PodemosajudarSubTitulo: '',

      PodemosAjudarItens: [],
      videoHome: [],
      videoHomeTitulo: '',
      videoHomeTexto: '',
      videoHomeLink: '',
      videoHomeImg: '',
      Ultimas: [],
      corretores: [],

      imovelTopo: [],
      imovelTopoReferencia: '',
      imovelTopoTitulo: '',
      imovelTopoArea: '',
      imovelTopoBanhos: '',
      imovelTopoQuartos: '',
      imovelTopoValor: '',
      imovelTopo_bg: '',
      imovelTopoLink: '',

      imoveisEmDestaque: [],

      imoveisLancamento: [],

      tiposDeImoveis: [],

      localidades: [],

      nossosServicos: [],
      nossosServicosTitulo: '',
      nossosServicos_bg: '',
      nossosServicosImg: '',

      nossosServicosItens: [],
      TiposImovel: [],
      Tiposelected: null,
      TiposNegocio:[],
      TiposNegocioselected: null,
    }
  },
  methods: {
    CarregaImovel(){
      this.$http
      .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + this.TiposNegocioselected.segmentoInstNivel1Id)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.TiposImovel = retornoAPI.filter((x) => x.visivel)
      });
    },
    randomNumber(value) {
      return Math.floor(Math.random() * (value));
    },
    ValidaBusca(){
      let _imovel = 0;
      let _negocio = 0;
      if (this.Tiposelected!=null){
        _imovel = this.Tiposelected.segmentoInstNivel2Id;
      }
      if (this.TiposNegocioselected!=null){
        _negocio = this.TiposNegocioselected.segmentoInstNivel1Id;
      }
      this.$redirect_reload('/imoveis/' + _imovel + '/' + _negocio)
    },
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/slider")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Sliders = retornoAPI.filter(x => x.visivel);

        this.Sliders.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

      this.$http
      .get(this.$apiUrl + "/segmentoInstNivel1")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.TiposNegocio = retornoAPI.filter((x) => x.visivel)
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/títuloitenshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.PodemosAjudar = retornoAPI;

        this.PodemosAjudarTitulo = this.PodemosAjudar[0].nome
        this.PodemosajudarSubTitulo = this.PodemosAjudar[0].campoExtra1

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.PodemosAjudarItens = retornoAPI.filter(x => x.visivel);

        this.PodemosAjudarItens.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/ultimasunidades")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Ultimas = retornoAPI.filter(x => x.visivel);

        this.Ultimas.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });




    this.$http
      .get(this.$apiUrl + "/institucional/telas/vídeohome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.videoHome = retornoAPI;

        this.videoHomeTitulo = this.videoHome[0].nome
        this.videoHomeTexto = this.videoHome[0].descricao1
        this.videoHomeLink = this.videoHome[0].linkBtn1
        if (this.videoHome[0].institucionalImg != '') {
          this.videoHomeImg = this.$apiUrlIndex + this.videoHome[0].institucionalImg[0].urlImagem
        }

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/corretores")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.corretores = retornoAPI.filter(x => x.visivel);

        this.corretores.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

  }

}

</script>

